import React from 'react'

function Newsletter() {
  return (
    <div className="--newsletter flex justify-center">
        <div className="wrapper">	   
            <div id="text-18" className="widget text-18 widget_text pull-left">
                <div className="widget-inner">			
                    <div className="textwidget">
                        <div className="need-help">
                            <h3>BESOIN D'AIDE ? APPELEZ NOUS QUAND VOUS VOULEZ</h3>
                            NOTRE EQUIPE EST DISPONIBLE 24/7 AU (00225) 272-024-3418
                        </div>
                    </div>
                </div>
            </div>
            <div id="text-19" className="widget text-19 widget_text pull-right">
                <div className="widget-inner">
                    <div className="textwidget">
                        <form id="mc4wp-form-1" className="mc4wp-form mc4wp-form-197" method="post" data-id={197} data-name>
                            <div className="mc4wp-form-fields">
                                    <div className="newsletter-content">
                                        <input type="email" id="mc4wp_email" className="newsletter-email" name="EMAIL" placeholder="Enter your email address" required />
                                        <input className="newsletter-submit" type="submit" defaultValue="Subscribe" />
                                    </div>
                            </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Newsletter