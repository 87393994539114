import { useSearchParams } from 'react-router-dom'
import useOnClickOutSide from '../..//utils/utilities/onClickOutSide'
import React, { ReactElement, useEffect, useRef, useState } from 'react'

const optionItems = [
  {
    title : 'Les plus demandés',
    value: 'most-popular'
  },
  {
    title : 'Nouvel arrivage',
    value: 'new-arrival'
  },
  {
    title : 'Prix croissant',
    value: 'price-az'
  },
  {
    title : 'Prix décroissant',
    value: 'price-za'
  }
]
function Filter() {
  const optionRef = useRef<any>(null)
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false)
  const [activeOption, setActiveOption] = useState<string>('most-popular')
  const [searchParams] = useSearchParams();
  const filterData = searchParams.get("filter");

  useEffect(() => {
    if(filterData) {
      setActiveOption(filterData)
    }
  }, [filterData])

  useOnClickOutSide(optionRef, () => setOptionsVisible(false))
  return (
    <div className="product-filter">
      <p 
      onClick={() => setOptionsVisible(true)}
      className={`filter-header ${optionsVisible ? 'filter-header-selected': ''} `}> <span>Trier par:</span> {optionItems.find((option: any) => option.value === activeOption)?.title} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
              <path d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
      </p>

      {optionsVisible && <div className="filter-select"
      ref={optionRef}
      >

        {optionItems.map(({title, value}: any, i: number) => {
          return <div className={`filter-select-option ${activeOption === value ? 'filter-select-option-active': ''}`}
          onClick={() => setActiveOption(value)}
          >
            <a href={`?filter=${value}`}>{title}</a>
          </div>
        })}
      </div>}
    </div>
  )
}

export default Filter