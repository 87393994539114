import Stepper from '../../--components/--stepper'
import React from 'react'

function Login() {
  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2>Login</h2>
                        <form className="form form-login login" method="post">
                          <p className="form-row form-row-wide">
                            <label htmlFor="username">Username or email address <span className="required">*</span></label>
                            <input type="text" className="input-text" name="username" id="username" />
                          </p>
                          <p className="form-row form-row-wide">
                            <label htmlFor="password">Password <span className="required">*</span></label>
                            <input className="input-text" type="password" name="password" id="password" />
                          </p>
                          <p className="lost_password">
                            <a href="./">Lost your password?</a>
                          </p>
                          <p className="form-row">
                            <input type="submit" className="button" name="login" defaultValue="Login" /> 
                          </p>
                          <p className="lost_password">
                            <a href="./">Register</a>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default Login