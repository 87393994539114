import React from 'react'

function PersonnalsInformations() {
  return (
    <form className="EditAccountForm edit-account"  method="post">
  <p className="form-row form-row--first form-row form-row-first">
    <label htmlFor="account_first_name">First name&nbsp;<span className="required">*</span></label>
    <input type="text" className="Input Input--text input-text" name="account_first_name" id="account_first_name" autoComplete="given-name" defaultValue="Marx" />
  </p>
  <p className="form-row form-row--last form-row form-row-last">
    <label htmlFor="account_last_name">Last name&nbsp;<span className="required">*</span></label>
    <input type="text" className="Input Input--text input-text" name="account_last_name" id="account_last_name" autoComplete="family-name" defaultValue="N'Guessan" />
  </p>
  <div className="clear" />
  <p className="form-row form-row--wide form-row form-row-wide">
    <label htmlFor="account_display_name">Display name&nbsp;<span className="required">*</span></label>
    <input type="text" className="Input Input--text input-text" name="account_display_name" id="account_display_name" defaultValue="nguessanmarx" /> <span><em>This will be how your name will be displayed in the account section and in reviews</em></span>
  </p>
  <div className="clear" />
  <p className="form-row form-row--wide form-row form-row-wide">
    <label htmlFor="account_email">Email address&nbsp;<span className="required">*</span></label>
    <input type="email" className="Input Input--email input-text" name="account_email" id="account_email" autoComplete="email" defaultValue="nguessanmarx@gmail.com" />
  </p>
  <fieldset>
    <legend>Password change</legend>
    <p className="form-row form-row--wide form-row form-row-wide">
      <label htmlFor="password_current">Current password (leave blank to leave unchanged)</label>
      <span className="password-input"><input type="password" className="Input Input--password input-text" name="password_current" id="password_current" autoComplete="off" /><span className="show-password-input" /></span>
    </p>
    <p className="form-row form-row--wide form-row form-row-wide">
      <label htmlFor="password_1">New password (leave blank to leave unchanged)</label>
      <span className="password-input"><input type="password" className="Input Input--password input-text" name="password_1" id="password_1" autoComplete="off" /><span className="show-password-input" /></span>
    </p>
    <p className="form-row form-row--wide form-row form-row-wide">
      <label htmlFor="password_2">Confirm new password</label>
      <span className="password-input"><input type="password" className="Input Input--password input-text" name="password_2" id="password_2" autoComplete="off" /><span className="show-password-input" /></span>
    </p>
  </fieldset>
  <div className="clear" />
  <p className='p-[3px]'>
    <button type="submit" className="Button button wp-element-button" name="save_account_details" value="Save changes">Save changes</button>
  </p>
</form>

  )
}

export default PersonnalsInformations