import Carousel from 'react-multi-carousel'
import ProductBigCard from '../--components/--product-big-card-two'
import SectionTitle from './--section-title'
import React from 'react'

function RelatedProductsList() {
  return (
    <div className="flex mb-5 justify-center w-full --best-seller-products-list">
        <div className="wrapper-inner w-full">
          <SectionTitle
          title={<> Vous <span>aimeriez</span> aussi </>}
          />
            <div className="w-full bg-[white] rounded-[5px] p-[15px] grid grid-cols-1 smallScreen:grid-cols-2 width650:grid-cols-1 smallLaptop:grid-cols-2 gap-x-[25px] gap-y-[20px] ">
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
            </div>
        </div>
      </div>
  )
}

export default RelatedProductsList