import React from 'react'

function PubSmallBanner() {
  return (
    <div className="flex justify-center w-full">
      <div className="wrapper">
        <div className="--small-banner">
            <img width={"100%"} height={163} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/05/banner-living.jpg" className="attachment-larges size-larges" alt="" />			
        </div>
      </div>
    </div>
  )
}

export default PubSmallBanner