import ProductBigCard from '../../../--components/--product-big-card'
import SectionTitle from '../../../--partials/--section-title'
import React from 'react'
import Carousel from 'react-multi-carousel'

function NewArrivalsProductsList() {
  return (
      <div className="flex justify-center w-full --best-seller-products-list">
          <div className="wrapper">
            <SectionTitle
            title={<> <span>Arrivage</span> (Dernières nouveautés) </>}
            />
             
            <div className="w-full grid grid-cols-1 width650:grid-cols-2 width1100:grid-cols-3 gap-x-[25px] gap-y-[20px] ">
                <ProductBigCard/>
                <ProductBigCard/>
                <ProductBigCard/>
                <ProductBigCard/>
                <ProductBigCard/>
                <ProductBigCard/>
            </div>
          </div>
        </div>
  )
}

export default NewArrivalsProductsList