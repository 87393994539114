import BigStockPuBanner from '../../../--partials/--big-stock-pub-banner'
import ProductBigStockageCard from '../../../--components/--product-big-stockage-card'
import SectionTitle from '../../../--partials/--section-title'

function BigStockProductsList() {
  return (
      <div className="flex justify-center w-full --big-stock-products-list">
          <div className="wrapper">
            <SectionTitle
            title={<> Le<span> grand </span> stockage </>}
            />
             
             <div className="wrap hidden miniLaptop:block">
              <div className="multi-category-wrapper">
                <BigStockPuBanner/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
              </div>
              <div className="multi-category-wrapper">
                <BigStockPuBanner/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
              </div>
              <div className="multi-category-wrapper">
                <BigStockPuBanner/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
              </div>
              <div className="multi-category-wrapper">
                <BigStockPuBanner/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
                <ProductBigStockageCard/>
              </div>
            </div>

            <div className="wrap hidden treffPunkTeSize:block miniLaptop:hidden ">
                <div className="w-full grid grid-cols-3 multi-category-wrapper">
                  <BigStockPuBanner/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <BigStockPuBanner/>
                  <ProductBigStockageCard/>
                  <BigStockPuBanner/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <BigStockPuBanner/>
              </div>
            </div>

            <div className="wrap block treffPunkTeSize:hidden ">
                <div className="w-full grid grid-cols-2 multi-category-wrapper">
                  <BigStockPuBanner/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <BigStockPuBanner/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <BigStockPuBanner/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <BigStockPuBanner/>
              </div>
            </div>

            

          </div>
        </div>
  )
}

export default BigStockProductsList