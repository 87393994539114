import React from 'react'

function BigStockPuBanner() {
  return (
    <div className="mcategory-item item-thumb">
        <img width={480} height={570} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2017/04/bn9.jpg" className="attachment-full size-full" alt="" />				
    </div>
  )
}

export default BigStockPuBanner