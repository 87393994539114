import React from 'react'
import ProductFull from './----product-full-image'
import ProductThumbsSlider from './----product-thumbs-sider'

function ProductImages() {
  return (
    <div className='--product-details-images' >
        <ProductFull/>
        <ProductThumbsSlider/>
    </div>
  )
}

export default ProductImages