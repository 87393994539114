import React from 'react'

function EmptyIllustration() {
  return (
    <div className='empty-illustration gap-y-10 flex flex-col justify-center items-center'>
        <svg width={54} height={55} viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M48.3068 48.6932C48.9658 49.3523 50.0342 49.3523 50.6932 48.6932C51.3523 48.0342 51.3523 46.9658 50.6932 46.3068L48.3068 48.6932ZM42.9068 43.2932L48.3068 48.6932L50.6932 46.3068L45.2932 40.9068L42.9068 43.2932ZM48.4875 32.65C48.4875 25.0078 42.2922 18.8125 34.65 18.8125V22.1875C40.4283 22.1875 45.1125 26.8717 45.1125 32.65H48.4875ZM34.65 18.8125C27.0078 18.8125 20.8125 25.0078 20.8125 32.65H24.1875C24.1875 26.8717 28.8717 22.1875 34.65 22.1875V18.8125ZM20.8125 32.65C20.8125 40.2922 27.0078 46.4875 34.65 46.4875V43.1125C28.8717 43.1125 24.1875 38.4283 24.1875 32.65H20.8125ZM34.65 46.4875C42.2922 46.4875 48.4875 40.2922 48.4875 32.65H45.1125C45.1125 38.4283 40.4283 43.1125 34.65 43.1125V46.4875Z" fill="#141B34" /><path opacity="0.4" d="M4.5 22.75L15.75 22.7501" stroke="#141B34" strokeWidth="3.375" strokeLinecap="round" strokeLinejoin="round" /><path d="M4.5 38.5H15.75" stroke="#141B34" strokeWidth="3.375" strokeLinecap="round" strokeLinejoin="round" /><path opacity="0.4" d="M4.5 7H42.75" stroke="#141B34" strokeWidth="3.375" strokeLinecap="round" strokeLinejoin="round" /></svg>

        <p className='text-black text-center text-xl not-italic font-semibold leading-[normal]'>Aucun resultat.</p>

        <div className="text-[#495055] text-center text-[15px] not-italic font-normal leading-[normal]">Veuillez vérifier l'orthographe ou essayer un autre mot-clé.</div>

        <div className="flex justify-center mb-24 mx-3 gap-5 select-auto">   
            <div className="flex-col flex justify-center gap-3 border p-6 rounded-2xl border-solid border-[#ec2535] bg-[#f1e3e4] max-w-lg items-center text-center">
                <div className="text-black text-lg not-italic font-semibold leading-[normal]">Vous ne trouvez pas ce que vous cherchez ?</div>
                <div className="text-black text-sm not-italic font-normal leading-5">Nous vous assurons que nous continuons à ajouter des produits. N'hésitez pas à nous contacter directement pour demander vos produits spécifiques.</div>
                <a target="_blank" href="https://twitter.com/huge_icons">
                    <button className="relative font-bold whitespace-nowrap text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 flex justify-center items-center gap-1 border shadow-[0px_1px_1px_0px_rgba(0,0,0,0.20)] px-4 py-3 rounded-lg border-solid border-[#C5C9D3] bg-white">Contactez nous <span className="text-gray-500 font-normal">(Ici)</span></button>
                </a>
            </div>
        </div>

    </div>
  )
}

export default EmptyIllustration