import useOnClickOutSide from '../..//utils/utilities/onClickOutSide';
import React, { useRef, useState } from 'react'

function ProductSearch() {

  const searchAppendRef = useRef<any>(null)

  const [showSearchAppend, setShowSearchAppend] = useState<boolean>(false);

  useOnClickOutSide(searchAppendRef, () => setShowSearchAppend(false))
  return (
    <div className='--product-search-field relative' >
      <button>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
          <path d="M14 14L16.5 16.5" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
          <path d="M16.4333 18.5252C15.8556 17.9475 15.8556 17.0109 16.4333 16.4333C17.0109 15.8556 17.9475 15.8556 18.5252 16.4333L21.5667 19.4748C22.1444 20.0525 22.1444 20.9891 21.5667 21.5667C20.9891 22.1444 20.0525 22.1444 19.4748 21.5667L16.4333 18.5252Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
          <path d="M16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16C12.866 16 16 12.866 16 9Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
      </svg>
      </button>

      <input type="text" placeholder='Recherche produits...' />

      {showSearchAppend && <div  ref={searchAppendRef} className="--search-append absolute top-[50px] left-0 ">
        <div className="autocomplete-suggestions">
          <div className="autocomplete-suggestion">
            <a className="media-left" href="./product/id">
              <img width={50} height={50} className="pull-left" src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2017/04/3-1-100x100.jpg" />
              <div className="media-body">
                <span><strong>Donec</strong> vel venenatis mauris</span>
                <div className="search-meta">
                  <p className="price">
                    <span className="-Price-amount amount">
                      <bdi> <span className="-Price-currencySymbol">$</span>155.00</bdi>
                    </span>
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="autocomplete-suggestion">
            <a className="media-left" href="./product/id">
              <img width={50} height={50} className="pull-left" src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2017/04/3-1-100x100.jpg" />
              <div className="media-body">
                <span><strong>Donec</strong> vel venenatis mauris</span>
                <div className="search-meta">
                  <p className="price">
                    <span className="-Price-amount amount">
                      <bdi> <span className="-Price-currencySymbol">$</span>155.00</bdi>
                    </span>
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="autocomplete-suggestion">
            <a className="media-left" href="./product/id">
              <img width={50} height={50} className="pull-left" src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2017/04/3-1-100x100.jpg" />
              <div className="media-body">
                <span><strong>Donec</strong> vel venenatis mauris</span>
                <div className="search-meta">
                  <p className="price">
                    <span className="-Price-amount amount">
                      <bdi> <span className="-Price-currencySymbol">$</span>155.00</bdi>
                    </span>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>}
    </div>
  )
}

export default ProductSearch