import Stepper from '../../--components/--stepper'
import React from 'react'

function Register() {
  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        
          <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
              <div className="post-300 page type-page status-publish hentry">
                
                <div className="entry-content">
                  <div className="woocommerce">
                    <div className="woocommerce-notices-wrapper" />
                    <div className="col2-set row" id="customer_login">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <h2>Inscription</h2>
                        <form className="woocommerce-form woocommerce-form-login login" method="post">
                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="username">Username <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="username">Username or email address <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                            </p>
                          </div>
                          <p className="form-row form-row-wide">
                              <label htmlFor="username">email address <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                          </p>

                          <div className="w-full flex flex-col width600:flex-row items-center">
                            <p className="form-row form-row-wide">
                              <label htmlFor="username">Gender <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="username">Phone number <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                            </p>
                            <p className="form-row form-row-wide">
                              <label htmlFor="username">City <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                            </p>
                          </div>
                          <p className="form-row form-row-wide">
                              <label htmlFor="username">Full adress <span className="required">*</span></label>
                              <input type="text" className="input-text" name="username" id="username" />
                          </p>
                          <div className="w-full flex flex-col width600:flex-row items-center">
                          <p className="form-row form-row-wide">
                            <label htmlFor="password">Password <span className="required">*</span></label>
                            <input className="input-text" type="password" name="password" id="password" />
                          </p>
                          <p className="form-row form-row-wide">
                            <label htmlFor="password">Confirm Password <span className="required">*</span></label>
                            <input className="input-text" type="password" name="password" id="password" />
                          </p>
                          </div>
                          
                          <p className="form-row">
                            <input type="submit" className="button" name="login" defaultValue="Login" /> 
                          </p>
                          <p className="lost_password">
                            <a href="./login">Login?</a>
                          </p>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-single">
                </div>			
              </div>
          </div>

        </div>
        
      </div>
  )
}

export default Register