import React from 'react'

function Informations() {
  return (
    <>
      <p>
                  Hello <strong>nguessanmarx</strong> (not <strong>nguessanmarx</strong>? <a href="./">Log out</a>)</p>
                <p>
                  From your account dashboard you can view your <a href="./profile/orders">recent orders</a>, manage your <a href="./">edit your password and account details</a>.</p>
    </>
  )
}

export default Informations