import React, { useState } from 'react'

function CustomReviewSar() {
    const [rating, setRating] = useState<number>(0);
    const [hover, setHover] = useState<number>(0);
    const [totalStars, setTotalStars] = useState(5);


  return (
    <>
    {[...Array(totalStars)].map((star, index) => {
        const currentRating = index + 1;

        return (
          <label key={index} className='custom-review'>
            <input
              key={star}
              type="radio"
              name="rating"
              value={currentRating}
              onChange={() => setRating(currentRating)}
            />
            <span
              className="star"
              style={{
                color:
                  currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9",
              }}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(0)}
            >
              &#9733;
            </span>
          </label>
        );
      })}
    </>
  )
}

export default CustomReviewSar