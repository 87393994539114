import React from 'react'

function HeaderCart() {
  return (
    <div className="--cart-side relative">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" color="#000000" fill="none">
            <path d="M3 2H4.30116C5.48672 2 6.0795 2 6.4814 2.37142C6.88331 2.74285 6.96165 3.36307 7.11834 4.60351L8.24573 13.5287C8.45464 15.1826 8.5591 16.0095 9.09497 16.5048C9.63085 17 10.4212 17 12.002 17H22" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
            <circle cx="11.5" cy="19.5" r="1.5" stroke="currentColor" stroke-width="1.5" />
            <circle cx="18.5" cy="19.5" r="1.5" stroke="currentColor" stroke-width="1.5" />
            <path d="M18 14H16C14.1144 14 13.1716 14 12.5858 13.4142C12 12.8284 12 11.8856 12 10V8C12 6.11438 12 5.17157 12.5858 4.58579C13.1716 4 14.1144 4 16 4H18C19.8856 4 20.8284 4 21.4142 4.58579C22 5.17157 22 6.11438 22 8V10C22 11.8856 22 12.8284 21.4142 13.4142C20.8284 14 19.8856 14 18 14Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5 7L17.5 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div className="--cart-count flex items-center justify-center absolute top-[-6px] right-[-8px] size-[20px] rounded-full ">+9</div>

        <div className="--mini-cart-wrapp">
            <div className="--minicart-padding">
                <div className="--minicart-title">
                    <span>Your Product</span>
                    <span className="--price-title">Price</span>
                </div>

                <ul className="--minicart-content">
                    <li>
                        <div className="--minicart-img">
                        <a href="./product/id" className="product-image">
                            <img width={60} height={60} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/15-300x300.jpg" className="attachment-70x60 size-70x60" alt="" decoding="async" loading="lazy" srcSet="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/15-300x300.jpg 300w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/15-100x100.jpg 100w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/15.jpg 600w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/15-90x90.jpg 90w" sizes="(max-width: 60px) 100vw, 60px" />						</a>
                        <div className="--product-action">
                            <a href="./product/id" className="--btn-remove" title="Remove this item"><span /></a>						</div>
                        </div>	 
                        <div className="--detail-item">
                            <div className="--product-details">
                                <div className="--product-information"> 
                                <div className="--product-name">
                                    <a href="./product/id">Donec vel venenatis</a>	  		
                                </div>
                                <div className="--qty">
                                    <span className="--qty-label">Quantity: </span>
                                    <span className="--qty-number">2</span>								</div>
                                </div>
                                <div className="--product-price">
                                <span className="--price"><span className="---Price-amount --amount" data-original="<bdi><span class=&quot;-Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">1000 F</span></span>		        		        		    		
                                </div>
                            </div>	
                        </div>			
                    </li>
                </ul>

                <div className="--cart-checkout">
                    <div className="--price-total">
                        <span className="--label-price-total">Total:</span>
                        <span className="--price-total-w"><span className="--price"><span className="---Price-amount --amount" data-original="<bdi><span class=&quot;-Price-currencySymbol&quot;>$</span>0.00</bdi>" data-price={0} title="Original price:0">$0.00</span></span></span>
                    </div>
                    <div className="--cart-links">
                        <div className="--cart-link"><a href="./cart" title="Cart">Aller au panier</a></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}

export default HeaderCart