import React from 'react'
import SideBestSeller from '../../../--partials/--best-seller'
import SideCategories from '../../../--partials/--side-categories'
import SectionTitle from '../../../--partials/--section-title'
import Filter from '../../../--partials/--filter'
import ProductBigCardTwo from '../../../--components/--product-big-card-two'
function CategoryLevelThree() {
  return (
    <div className='--product-details w-full flex justify-center' >
    <div className="wrapper flex flex-col-reverse width650:flex-row gap-x-[25px] ">
      <div className="--product-details--left-section block width500:flex justify-center gap-x-5 width650:block">
        <SideCategories/>
        <SideBestSeller/>
      </div>

      <div className="--product-details--right-section">

      <div className="flex justify-center w-full --best-seller-products-list">
      <div className="wrapper-inner w-full">
        <div className="w-full flex flex-col smallLaptop:flex-row items-start smallLaptop:justify-between">
                <SectionTitle
                title={<> Categorie: <span><em>Ordinateurs de bureau</em></span> </>}
                />
                <Filter/>
            </div>
            <div className="w-full mt-3 bg-[white] rounded-[5px] p-[15px] grid grid-cols-1 smallScreen:grid-cols-2 width650:grid-cols-1 smallLaptop:grid-cols-2 gap-x-[10px] bigLaptop:gap-x-[25px] gap-y-[20px] ">
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  <ProductBigCardTwo/>
                  
            </div>
            <div className="flex w-full items-center justify-center --load-more">

                <button type='button' className="button">
                  Chargez plus
                </button >
              </div>
        </div>
      </div>
      </div>
    </div>
    
</div>
  )
}

export default CategoryLevelThree