import React from 'react'

function OrderDetails() {
  return (
    <>
        <p>
          Order #<mark className="order-number">1454</mark> was placed on <mark className="order-date">September 26, 2024</mark> and is currently <mark className="order-status">Processing</mark>.</p>
        <section className="order-details">
          <h2 className="order-details__title">Order details</h2>
          <table className="table table--order-details shop_table order_details">
            <thead>
              <tr>
                <th className="table__product-name product-name">Product</th>
                <th className="table__product-table product-total">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr className="table__line-item order_item">
                <td className="table__product-name product-name">
                  <a href="./product/id">Donec vel venenatis</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                <td className="table__product-total product-total">
                  <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">10000 F</span>	</td>
              </tr>
              <tr className="table__line-item order_item">
                <td className="table__product-name product-name">
                  <a href="./product/id">Donec vel mauris</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                <td className="table__product-total product-total">
                  <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">10000 F</span>	</td>
              </tr>
              <tr className="table__line-item order_item">
                <td className="table__product-name product-name">
                  <a href="./product/id">Aute beef</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                <td className="table__product-total product-total">
                  <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>190.00</bdi>" data-price={190} title="Original price:190">19000 F</span>	</td>
              </tr>
              <tr className="table__line-item order_item">
                <td className="table__product-name product-name">
                  <a href="./product/id">Kore mire dase</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                <td className="table__product-total product-total">
                  <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>160.00</bdi>" data-price={160} title="Original price:160">16000 F</span>	</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th scope="row">Subtotal:</th>
                <td><span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>550.00" data-price={550} title="Original price:550">5500 F</span></td>
              </tr>
              <tr>
                <th scope="row">Payment method:</th>
                <td>Cash on delivery</td>
              </tr>
              <tr>
                <th scope="row">Total:</th>
                <td><span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>550.00" data-price={550} title="Original price:550">5500 F</span></td>
              </tr>
            </tfoot>
          </table>
        </section>
        <section className="customer-details">
          <h2 className="column__title">Billing address</h2>
          <address>
            Marx N'Guessan<br />Marx Perso<br />Abobo Biabou<br />Abidjan<br />abidjan<br />80331<br />Ivory Coast
            <p className="customer-details--phone">+2250787467290</p>
            <p className="customer-details--email">nguessanmarx@gmail.com</p>
          </address>
        </section>
        <p className='mt-5 order-details-action'>
          <button type="submit" className="Button button wp-element-button" name="save_account_details" value="Save changes">Save changes</button>
        </p>
    </>
  )
}

export default OrderDetails