import React from 'react'

function ProductFull() {
  return (
    <div className='--product-full-image' >
       <a href="./product/id">
            <img src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char2.jpg" className="wp-post-image" />
        </a>
    </div>
  )
}

export default ProductFull