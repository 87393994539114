import Stepper from '../../--components/--stepper'
import React from 'react'

function Cart() {
  return (
    <div className='--cart-page car-page w-full flex justify-center' >
        <div className="wrapper">
          <Stepper
          active={1}
          />
          <form className="cart-form" action="" method="post">
            <table className="shop_table shop_table_responsive cart cart-form__contents" cellSpacing={0}>
              <thead>
                <tr>
                  <th className="product-remove"><span className="screen-reader-text">Remove item</span></th>
                  <th className="product-thumbnail"><span className="screen-reader-text">Thumbnail image</span></th>
                  <th className="product-name font-semibold">Product</th>
                  <th className="product-price">Price</th>
                  <th className="product-quantity">Quantity</th>
                  <th className="product-subtotal">Subtotal</th>
                </tr>
              </thead>
              <tbody>
                <tr className="cart-form__cart-item cart_item">
                  <td className="product-remove">
                    <a href="./product/id" className="remove" aria-label="Remove this item" data-product_id={259} data-product_sku>×</a>						</td>
                  <td className="product-thumbnail">
                    <a href="./product/id"><img width={300} height={300} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/11-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt='' decoding="async" loading="lazy" srcSet="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/11-300x300.jpg 300w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/11-100x100.jpg 100w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/11.jpg 600w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/11-90x90.jpg 90w" sizes="(max-width: 300px) 100vw, 300px" /></a>						</td>
                  <td className="product-name font-semibold" data-title="Product:">
                    <a href="./product/id">Caquetoire</a>						</td>
                  <td className="product-price" data-title="Price:">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>160.00</bdi>" data-price={160} title="Original price:160">16000 F</span>						</td>
                  <td className="product-quantity" data-title="Quantity:">
                    <div className="quantity w-full flex justify-end bigTablet:justify-start items-center font-bold">
                      1
                    </div>
                  </td>
                  <td className="product-subtotal" data-title="Subtotal:">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>160.00</bdi>" data-price={160} title="Original price:160">16000 F</span>						</td>
                </tr>
                <tr className="cart-form__cart-item cart_item">
                  <td className="product-remove">
                    <a href="./product/id" className="remove" aria-label="Remove this item" data-product_id={862} data-product_sku>×</a>						</td>
                  <td className="product-thumbnail">
                    <a href="./product/id"><img width={300} height={300} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char3-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt='' decoding="async" loading="lazy" srcSet="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char3-300x300.jpg 300w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char3-100x100.jpg 100w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char3.jpg 600w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char3-90x90.jpg 90w" sizes="(max-width: 300px) 100vw, 300px" /></a>						</td>
                  <td className="product-name font-semibold" data-title="Product">
                    <a href="./product/id">Sausage chicken</a>						</td>
                  <td className="product-price" data-title="Price">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>120.00</bdi>" data-price={120} title="Original price:120">12000 F</span>						</td>
                    <td className="product-quantity" data-title="Quantity">
                    <div className="quantity w-full flex justify-end bigTablet:justify-start items-center font-bold">
                      1
                    </div>
                  </td>
                  <td className="product-subtotal" data-title="Subtotal">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>120.00</bdi>" data-price={120} title="Original price:120">12000 F</span>						</td>
                </tr>
                <tr className="cart-form__cart-item cart_item">
                  <td className="product-remove">
                    <a href="./product/id" className="remove" aria-label="Remove this item" data-product_id={714} data-product_sku>×</a>						</td>
                  <td className="product-thumbnail">
                    <a href="./product/id"><img width={300} height={300} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt='' decoding="async" loading="lazy" srcSet="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-300x300.jpg 300w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg 100w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13.jpg 600w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-90x90.jpg 90w" sizes="(max-width: 300px) 100vw, 300px" /></a>						</td>
                  <td className="product-name font-semibold" data-title="Product">
                    <a href="./product/id">Donec vel mauris</a>						</td>
                  <td className="product-price" data-title="Price">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">10000 F</span>						</td>
                    <td className="product-quantity" data-title="Quantity">
                    <div className="quantity w-full flex justify-end bigTablet:justify-start items-center font-bold">
                      1
                    </div>
                  </td>
                  <td className="product-subtotal" data-title="Subtotal">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">10000 F</span>						</td>
                </tr>
                <tr className="cart-form__cart-item cart_item">
                  <td className="product-remove">
                    <a href="./product/id" className="remove" aria-label="Remove this item" data-product_id={252} data-product_sku="PRT - 123">×</a>						</td>
                  <td className="product-thumbnail">
                    <a href="./product/id"><img width={300} height={300} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/7-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt='' decoding="async" loading="lazy" srcSet="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/7-300x300.jpg 300w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/7-100x100.jpg 100w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/7.jpg 600w, https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/7-90x90.jpg 90w" sizes="(max-width: 300px) 100vw, 300px" /></a>						</td>
                  <td className="product-name font-semibold" data-title="Product">
                    <a href="./product/id">Aliquip pork</a>						</td>
                  <td className="product-price" data-title="Price">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>150.00</bdi>" data-price={150} title="Original price:150">15000 F</span>						</td>
                    <td className="product-quantity" data-title="Quantity">
                    <div className="quantity w-full flex justify-end bigTablet:justify-start items-center font-bold">
                      1
                    </div>
                  </td>
                  <td className="product-subtotal" data-title="Subtotal">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>150.00</bdi>" data-price={150} title="Original price:150">15000 F</span>						</td>
                </tr>
                <tr>
                  <td colSpan={6} className="actions">
                    <div className="coupon">
                      <label htmlFor="coupon_code" className="screen-reader-text">Coupon:</label> <input type="text" name="coupon_code" className="input-text" id="coupon_code" placeholder="Coupon code" /> <button type="submit" className="button wp-element-button" name="apply_coupon" value="Apply coupon">Apply coupon</button>
                    </div>
                    
                    </td>
                </tr>
              </tbody>
            </table>
          </form>

          <div className="cart-collaterals">
            <div className="cart_totals">
              <h2>Cart totals</h2>
              <table cellSpacing={0} className="shop_table shop_table_responsive">
                <tbody><tr className="cart-subtotal">
                    <th>Subtotal</th>
                    <td data-title="Subtotal"><span className="woocommerce-Price-amount amount" data-original="<bdi><span class=&quot;woocommerce-Price-currencySymbol&quot;>$</span>530.00</bdi>" data-price={530} title="Original price:530">53000 F</span></td>
                  </tr>
                  <tr className="order-total">
                    <th>Total</th>
                    <td data-title="Total"><strong><span className="woocommerce-Price-amount amount" data-original="<bdi><span class=&quot;woocommerce-Price-currencySymbol&quot;>$</span>530.00</bdi>" data-price={530} title="Original price:530">53000 F</span></strong> </td>
                  </tr>
                </tbody></table>
              <div className="wc-proceed-to-checkout">
                <a href="./checkout" className="checkout-button button alt wc-forward wp-element-button">
                  Proceed to checkout</a>
              </div>
            </div>
          </div>

        </div>
        
      </div>
  )
}

export default Cart