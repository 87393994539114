import React from 'react'
import Carousel from 'react-multi-carousel'
import CategoriesDeals from './----categories-deals'
import BannerSlide from './----banner-slider'
import PopularProductsList from './----popular-products-list'
import useWindowSize from '../../../../utils/utilities/useWindowSize'

function BigBanner() {
  const [windowWidth, _] = useWindowSize();
  return (
    <div className="w-full flex justify-center">
      <div className="wrapper">
        {windowWidth < 992 && <div className='--home-big-banner' >
          <BannerSlide/>
        </div>}
        <div className='--home-big-banner' >
          <CategoriesDeals/>
          {windowWidth >= 992 && <BannerSlide/>}
          <PopularProductsList/>
        </div>
      </div>
    </div>
  )
}

export default BigBanner