import React from 'react'
import Carousel from 'react-multi-carousel'
function ProductThumbsSlider() {
  return (
    <div className='--product-thumbs-slider'>
        <Carousel
            responsive={{
              mobile: {
                breakpoint: { max: 4000, min: 0 },
                items: 4,
              },
            }}
            showDots={false}
            infinite
            swipeable={true}
            draggable={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="transform 300ms ease-in-out"
            transitionDuration={500}
            partialVisible={false}
            sliderClass='gap-x-5'
          >
            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>

            <div className="item-thumbnail-product">
                <div className="thumbnail-wrapper">
                    <img width={70} height={70} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/02/13-100x100.jpg" className="attachment-shop_thumbnail size-shop_thumbnail" />			
                </div>
            </div>


        </Carousel>
    </div>
  )
}

export default ProductThumbsSlider