import React from 'react'

function PassInformations() {
  return (
    <form className="EditAccountForm edit-account"  method="post">
  <fieldset>
    <legend>Password change</legend>
    <p className="form-row form-row--wide form-row form-row-wide">
      <label htmlFor="password_current">Current password (leave blank to leave unchanged)</label>
      <span className="password-input"><input type="password" className="Input Input--password input-text" name="password_current" id="password_current" autoComplete="off" /><span className="show-password-input" /></span>
    </p>
    <p className="form-row form-row--wide form-row form-row-wide">
      <label htmlFor="password_1">New password (leave blank to leave unchanged)</label>
      <span className="password-input"><input type="password" className="Input Input--password input-text" name="password_1" id="password_1" autoComplete="off" /><span className="show-password-input" /></span>
    </p>
    <p className="form-row form-row--wide form-row form-row-wide">
      <label htmlFor="password_2">Confirm new password</label>
      <span className="password-input"><input type="password" className="Input Input--password input-text" name="password_2" id="password_2" autoComplete="off" /><span className="show-password-input" /></span>
    </p>
  </fieldset>
  <div className="clear" />
  <p className='p-[3px]'>
    <button type="submit" className="Button button wp-element-button" name="save_account_details" value="Save changes">Save changes</button>
  </p>
</form>

  )
}

export default PassInformations