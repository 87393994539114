import BigStockPuBanner from '../../../--partials/--big-stock-pub-banner'
import ProductBigStockageCard from '../../../--components/--product-big-stockage-card'
import SectionTitle from '../../../--partials/--section-title'
import React from 'react'

function FullProductsList() {
  return (
    <div className="flex justify-center w-full --full-products-list mt-5">
          <div className="wrapper">
          <SectionTitle
            title={<> <span> Explorez </span> nos produits </>}
            />
             <div className="wrap">
                <div className="multi-category-wrapper">
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                  <ProductBigStockageCard/>
                </div>
                
              </div>

              <div className="flex w-full items-center justify-center --load-more">

                <button type='button' className="button">
                  Chargez plus
                </button >
              </div>

          </div>
      </div>
  )
}

export default FullProductsList