import Stepper from '../../--components/--stepper'
import React from 'react'

function OrderResume() {
  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
        <Stepper
          active={4}
          />
        <div className="order">
          <p className="notice notice--success thankyou-order-received">Thank you. Your order has been received.</p>
          <ul className="order-overview thankyou-order-details order_details">
            <li className="order-overview__order order">
              Order number:					<strong>1454</strong>
            </li>
            <li className="order-overview__date date">
              Date:					<strong>September 26, 2024</strong>
            </li>
            <li className="order-overview__email email">
              Email:						<strong>nguessanmarx@gmail.com</strong>
            </li>
            <li className="order-overview__total total">
              Total:					<strong><span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>550.00</bdi>" data-price={550} title="Original price:550">5500 F</span></strong>
            </li>
            <li className="order-overview__payment-method method">
              Payment method:						<strong>Cash on delivery</strong>
            </li>
          </ul>
          <section className="the-order-details">
            <h2 className="order-details__title">Order details</h2>
            <table className="table table--order-details shop_table order_details">
              <thead>
                <tr>
                  <th className="table__product-name product-name">Product</th>
                  <th className="table__product-table product-total">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr className="table__line-item order_item">
                  <td className="table__product-name product-name">
                    <a href="./product/id">Donec vel venenatis</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                  <td className="table__product-total product-total">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">10000 F</span>	</td>
                </tr>
                <tr className="table__line-item order_item">
                  <td className="table__product-name product-name">
                    <a href="./product/id">Donec vel mauris</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                  <td className="table__product-total product-total">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>100.00</bdi>" data-price={100} title="Original price:100">10000 F</span>	</td>
                </tr>
                <tr className="table__line-item order_item">
                  <td className="table__product-name product-name">
                    <a href="./product/id">Aute beef</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                  <td className="table__product-total product-total">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>190.00</bdi>" data-price={190} title="Original price:190">19000 F</span>	</td>
                </tr>
                <tr className="table__line-item order_item">
                  <td className="table__product-name product-name">
                    <a href="./product/id">Kore mire dase</a> <strong className="product-quantity">×&nbsp;1</strong>	</td>
                  <td className="table__product-total product-total">
                    <span className="Price-amount amount" data-original="<bdi><span class=&quot;Price-currencySymbol&quot;>$</span>160.00</bdi>" data-price={160} title="Original price:160">16000 F</span>	</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th scope="row">Subtotal:</th>
                  <td><span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>550.00" data-price={550} title="Original price:550">5500 F</span></td>
                </tr>
                <tr>
                  <th scope="row">Payment method:</th>
                  <td>Cash on delivery</td>
                </tr>
                <tr>
                  <th scope="row">Total:</th>
                  <td><span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>550.00" data-price={550} title="Original price:550">5500 F</span></td>
                </tr>
              </tfoot>
            </table>
          </section>
          <section className="customer-details">
            <h2 className="column__title">Billing address</h2>
            <address>
              Marx N'Guessan<br />Marx Perso<br />Abobo Biabou<br />Abidjan<br />abidjan<br />80331<br />Ivory Coast
              <p className="customer-details--phone">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
                    <path d="M5 9C5 5.70017 5 4.05025 6.02513 3.02513C7.05025 2 8.70017 2 12 2C15.2998 2 16.9497 2 17.9749 3.02513C19 4.05025 19 5.70017 19 9V15C19 18.2998 19 19.9497 17.9749 20.9749C16.9497 22 15.2998 22 12 22C8.70017 22 7.05025 22 6.02513 20.9749C5 19.9497 5 18.2998 5 15V9Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
                    <path d="M11 19H13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 2L9.089 2.53402C9.28188 3.69129 9.37832 4.26993 9.77519 4.62204C10.1892 4.98934 10.7761 5 12 5C13.2239 5 13.8108 4.98934 14.2248 4.62204C14.6217 4.26993 14.7181 3.69129 14.911 2.53402L15 2" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                +2250787467290</p>
              <p className="customer-details--email">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" color="#444444" fill="none">
                    <path d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                    <path d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round" />
                </svg>
                nguessanmarx@gmail.com</p>
            </address>
          </section>
          <div id="payment" className=" mt-5 checkout-payment">
            <ul className="wc_payment_methods payment_methods methods">
              {/* <li className="wc_payment_method payment_method_bacs">
                <input id="payment_method_bacs" type="radio" className="input-radio" name="payment_method" defaultValue="bacs" data-order_button_text />
                <label htmlFor="payment_method_bacs">
                  Direct bank transfer 	</label>
                <div className="payment_box payment_method_bacs" style={{display: 'none'}}>
                  <p>Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.</p>
                </div>
              </li>
              <li className="wc_payment_method payment_method_cheque">
                <input id="payment_method_cheque" type="radio" className="input-radio" name="payment_method" defaultValue="cheque" data-order_button_text />
                <label htmlFor="payment_method_cheque">
                  Check payments 	</label>
                <div className="payment_box payment_method_cheque" style={{display: 'none'}}>
                  <p>Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</p>
                </div>
              </li> */}
              <li className="wc_payment_method payment_method_cod">
                <input id="payment_method_cod" type="radio" checked className="input-radio" name="payment_method" defaultValue="cod" data-order_button_text />
                <label htmlFor="payment_method_cod">
                  Cash on delivery 	</label>
                <div className="payment_box payment_method_cod">
                  <p>Pay with cash upon delivery.</p>
                </div>
              </li>
            </ul>
            <div className="form-row place-order">
              <button type="submit" className="button alt wp-element-button" name="woocommerce_checkout_place_order" id="place_order" value="Place order" data-value="Place order">Place order</button>
              </div>
          </div>
        </div>

        </div>
        
      </div>
  )
}

export default OrderResume