import React, { ReactNode } from 'react'

interface SubmenuItemProps {
  title: string
  icon: ReactNode
  link: string
}

interface MenuItemProps {
  title: string
  icon: ReactNode
  link: string
  items : Array<SubmenuItemProps>
}
function MenuItem({title, icon, link, items}: MenuItemProps) {
  return (
    <div className='--menu-item relative z-[100] ' >
      <div className="flex items-center gap-x-[5px] --menu ">
        {icon}
          <span> {title} </span>
      </div>

      <div className="absolute top-0 left-0 w-full z-[-1] ">
        <div className="--menu-item-dropdown mt-[40px]">
          <div className="-menu-item-dropdown-item">
            <button type='button'>Desktops</button>
            <ul>
              <li><button type='button'>Desktop 1</button></li>
              <li><button type='button'>Desktop 2</button></li>
            </ul>
          </div>

          <div className="-menu-item-dropdown-item">
            <button type='button'>Laptops</button>
            <ul>
              <li><button type='button'>Laptop 1</button></li>
              <li><button type='button'>Laptop 2</button></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuItem