import React from 'react'

function Orders() {
  return (
    <table className="orders-table MyAccount-orders shop_table shop_table_responsive my_account_orders account-orders-table">
      <thead>
        <tr>
          <th className="orders-table__header orders-table__header-order-number"><span className="nobr">Order</span></th>
          <th className="orders-table__header orders-table__header-order-date"><span className="nobr">Date</span></th>
          <th className="orders-table__header orders-table__header-order-status"><span className="nobr">Status</span></th>
          <th className="orders-table__header orders-table__header-order-total"><span className="nobr">Total</span></th>
          <th className="orders-table__header orders-table__header-order-actions"><span className="nobr">Actions</span></th>
        </tr>
      </thead>
      <tbody>
        <tr className="orders-table__row orders-table__row--status-processing order">
          <td className="orders-table__cell orders-table__cell-order-number" data-title="Order">
            <a href="./profile/orders/id">
              #1454								</a>
          </td>
          <td className="orders-table__cell orders-table__cell-order-date" data-title="Date">
            <time dateTime="2024-09-26T00:33:10+00:00">September 26, 2024</time>
          </td>
          <td className="orders-table__cell orders-table__cell-order-status" data-title="Status">
            Processing
          </td>
          <td className="orders-table__cell orders-table__cell-order-total" data-title="Total">
            <span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>550.00" data-price={550} title="Original price:550">5500 F</span> for 4 items
          </td>
          <td className="orders-table__cell orders-table__cell-order-actions" data-title="Actions">
            <a href="./profile/orders/id" className="button wp-element-button button font-bold view">View</a>													</td>
        </tr>
        <tr className="orders-table__row orders-table__row--status-processing order">
          <td className="orders-table__cell orders-table__cell-order-number" data-title="Order">
            <a href="./profile/orders/id">
              #1453								</a>
          </td>
          <td className="orders-table__cell orders-table__cell-order-date" data-title="Date">
            <time dateTime="2024-09-20T22:18:14+00:00">September 20, 2024</time>
          </td>
          <td className="orders-table__cell orders-table__cell-order-status" data-title="Status">
            Processing
          </td>
          <td className="orders-table__cell orders-table__cell-order-total" data-title="Total">
            <span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>530.00" data-price={530} title="Original price:530">5300 F</span> for 4 items
          </td>
          <td className="orders-table__cell orders-table__cell-order-actions" data-title="Actions">
            <a href="./profile/orders/id" className="button wp-element-button button font-bold view">View</a>													</td>
        </tr>
        <tr className="orders-table__row orders-table__row--status-processing order">
          <td className="orders-table__cell orders-table__cell-order-number" data-title="Order">
            <a href="./profile/orders/id">
              #1452								</a>
          </td>
          <td className="orders-table__cell orders-table__cell-order-date" data-title="Date">
            <time dateTime="2024-09-20T13:28:50+00:00">September 20, 2024</time>
          </td>
          <td className="orders-table__cell orders-table__cell-order-status" data-title="Status">
            Processing
          </td>
          <td className="orders-table__cell orders-table__cell-order-total" data-title="Total">
            <span className="Price-amount amount" data-original="<span class=&quot;Price-currencySymbol&quot;>$</span>280.00" data-price={280} title="Original price:280">28000 F</span> for 2 items
          </td>
          <td className="orders-table__cell orders-table__cell-order-actions" data-title="Actions">
            <a href="./profile/orders/id" className="button wp-element-button button font-bold view">View</a>													</td>
        </tr>
      </tbody>
    </table>

  )
}

export default Orders