import React, { ReactElement } from 'react'
interface SectionTitleProps {
  title: ReactElement | string
}
function SectionTitle({title}: SectionTitleProps) {
  return (
    <div className="--section-title"><h2>{title}</h2></div>
  )
}

export default SectionTitle