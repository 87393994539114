import React from 'react'
import { useNavigate } from 'react-router-dom'
interface StepperProps{
    active: number
}
function Stepper({active}: StepperProps) {
  const navigate = useNavigate()
  return (
    <div className='--stepper'>
         <ol className="flex flex-col width600:flex-row items-start width600:items-center w-full gap-y-4 gap-x-4">
            <li className="relative ">
                <a href="#" 
                onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/cart')
                }}
                className="flex items-center font-medium w-full  ">
                <span className="w-6 h-6 bg-[#001c96] border border-transparent rounded-full flex justify-center items-center mr-3 text-sm text-white lg:w-8 lg:h-8"> 1 </span>
                <div className="block">
                    <h4 className="text-base  text-[#001c96]">Panier</h4>
                </div>
                <svg className="w-5 h-5 ml-2 stroke-[#001c96] sm:ml-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 18L9.67462 13.0607C10.1478 12.5607 10.3844 12.3107 10.3844 12C10.3844 11.6893 10.1478 11.4393 9.67462 10.9393L5 6M12.6608 18L17.3354 13.0607C17.8086 12.5607 18.0452 12.3107 18.0452 12C18.0452 11.6893 17.8086 11.4393 17.3354 10.9393L12.6608 6" stroke="stroke-current" strokeWidth="1.6" strokeLinecap="round" />
                </svg>
                </a>
            </li>
            
            <li className="relative  ">
                <a 
                onClick={(e: any) => {
                    e.preventDefault();
                }}
                className="flex items-center font-medium w-full  ">
                <span className={`w-6 h-6 border ${active > 1 ? 'bg-[#001c96] border-transparent text-white ': 'bg-gray-50 border-gray-200'} rounded-full flex justify-center items-center mr-3 text-sm  lg:w-8 lg:h-8`}>2</span>
                    <div className="block">
                        <h4 className={`text-base  ${active > 1 ? 'text-[#001c96]':'text-gray-900'}`}>Authentification</h4>
                    </div>
                    <svg className={`w-5 h-5 ml-2 ${active > 1 ? 'stroke-[#001c96]':'stroke-gray-900'} sm:ml-4`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 18L9.67462 13.0607C10.1478 12.5607 10.3844 12.3107 10.3844 12C10.3844 11.6893 10.1478 11.4393 9.67462 10.9393L5 6M12.6608 18L17.3354 13.0607C17.8086 12.5607 18.0452 12.3107 18.0452 12C18.0452 11.6893 17.8086 11.4393 17.3354 10.9393L12.6608 6" stroke="stroke-current" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>
                </a>
            </li>

            <li className="relative  ">
                <a 
                onClick={(e: any) => {
                    e.preventDefault();
                    navigate('/checkout')
                }}
                href="#" 
                className="flex items-center font-medium w-full  ">
                <span className={`w-6 h-6 border ${active > 2 ? 'bg-[#001c96] border-transparent text-white ': 'bg-gray-50 border-gray-200'} rounded-full flex justify-center items-center mr-3 text-sm  lg:w-8 lg:h-8`}>3</span>
                    <div className="block">
                        <h4 className={`text-base  ${active > 2 ? 'text-[#001c96]':'text-gray-900'}`}>Informations de la livraison</h4>
                    </div>
                    <svg className={`w-5 h-5 ml-2 ${active > 2 ? 'stroke-[#001c96]':'stroke-gray-900'} sm:ml-4`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 18L9.67462 13.0607C10.1478 12.5607 10.3844 12.3107 10.3844 12C10.3844 11.6893 10.1478 11.4393 9.67462 10.9393L5 6M12.6608 18L17.3354 13.0607C17.8086 12.5607 18.0452 12.3107 18.0452 12C18.0452 11.6893 17.8086 11.4393 17.3354 10.9393L12.6608 6" stroke="stroke-current" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>
                </a>
            </li>

            <li className="relative ">
                <a className="flex items-center font-medium w-full  ">
                <span className={`w-6 h-6 border ${active > 3 ? 'bg-[#001c96] border-transparent text-white ': 'bg-gray-50 border-gray-200'} rounded-full flex justify-center items-center mr-3 text-sm  lg:w-8 lg:h-8`}>4</span>
                    <div className="block">
                        <h4 className={`text-base  ${active > 3 ? 'text-[#001c96]':'text-gray-900'}`}>Résumé</h4>
                    </div>
                </a>
            </li>
            
        </ol>

    </div>
  )
}

export default Stepper