import Stepper from '../../--components/--stepper'
import React from 'react'

function Wishlist() {
  return (
    <div className='--cart-page car-page w-full flex justify-center' >
        <div className="wrapper">
        <table className="shop_table cart wishlist_table wishlist_view traditional responsive   " data-pagination="no" data-per-page={5} data-page={1} data-id={5} data-token="OPTXYX2U4HQR">
          <thead>
            <tr>
              <th className="product-thumbnail" />
              <th className="product-name">
                <span className="nobr">
                  Product name			</span>
              </th>
              <th className="product-price">
                <span className="nobr">
                  Unit price				</span>
              </th>
              <th className="product-stock-status">
                <span className="nobr">
                  Stock status				</span>
              </th>
              <th className="product-add-to-cart">
                <span className="nobr">
                </span>
              </th>
            </tr>
          </thead>
          <tbody className="wishlist-items-wrapper">
            <tr id="yith-wcwl-row-260" data-row-id={260}>
              <td className="product-thumbnail">
              <div>
                          <a href="./product/id" className="remove remove_from_wishlist" title="Remove this product">×</a>
                      </div>
                <a href="./product/id">
                  <img width={300} height={300} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char2-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt='' loading="lazy" />						</a>
              </td>
              <td className="product-name" data-title="Product name:">
                <a href="./product/id">
                  Balans chair						</a>
              </td>
              <td className="product-price" data-title="Unit price:">
                <del aria-hidden="true"><span className="woocommerce-Price-amount amount" data-original="<span class=&quot;woocommerce-Price-currencySymbol&quot;>$</span>180.00" data-price={180} title="Original price:180">18000 F</span></del> <ins><span className="woocommerce-Price-amount amount" data-original="<span class=&quot;woocommerce-Price-currencySymbol&quot;>$</span>175.00" data-price={175} title="Original price:175">17500 F</span></ins>
              </td>
              <td className="product-stock-status" data-title="Stock status">
                <span className="wishlist-in-stock">In Stock</span>
              </td>
              <td className="product-add-to-cart" data-title="Action:">
                {/* Date added */}
                {/* Add to cart button */}
                <a href="?add-to-cart=260&remove_from_wishlist_after_add_to_cart=260&wishlist_id=5&wishlist_token=OPTXYX2U4HQR" data-quantity={1} className="wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart add_to_cart alt" data-product_id={260} data-product_sku aria-label="Add “Balans chair” to your cart" rel="nofollow">Add to Cart</a>							
                {/* Change wishlist */}
                {/* Remove from wishlist */}
              </td>
            </tr>
            <tr id="yith-wcwl-row-260" data-row-id={260}>
              <td className="product-thumbnail">
              <div>
                          <a href="./product/id" className="remove remove_from_wishlist" title="Remove this product">×</a>
                      </div>
                <a href="./product/id">
                  <img width={300} height={300} src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/03/char2-300x300.jpg" className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt='' loading="lazy" />						</a>
              </td>
              <td className="product-name" data-title="Product name">
                <a href="./product/id">
                  Balans chair						</a>
              </td>
              <td className="product-price" data-title="Unit price">
                <del aria-hidden="true"><span className="woocommerce-Price-amount amount" data-original="<span class=&quot;woocommerce-Price-currencySymbol&quot;>$</span>180.00" data-price={180} title="Original price:180">10000 F</span></del> <ins><span className="woocommerce-Price-amount amount" data-original="<span class=&quot;woocommerce-Price-currencySymbol&quot;>$</span>175.00" data-price={175} title="Original price:175">17500 F</span></ins>
              </td>
              <td className="product-stock-status" data-title="Stock status">
                <span className="wishlist-in-stock">In Stock</span>
              </td>
              <td className="product-add-to-cart" data-title="Action">
                {/* Date added */}
                {/* Add to cart button */}
                <a href="?add-to-cart=260&remove_from_wishlist_after_add_to_cart=260&wishlist_id=5&wishlist_token=OPTXYX2U4HQR" data-quantity={1} className="wp-element-button product_type_simple add_to_cart_button ajax_add_to_cart add_to_cart alt" data-product_id={260} data-product_sku aria-label="Add “Balans chair” to your cart" rel="nofollow">Add to Cart</a>							
                {/* Change wishlist */}
                {/* Remove from wishlist */}
              </td>
            </tr>
          </tbody>
        </table>


        </div>
        
      </div>
  )
}

export default Wishlist