import Carousel from 'react-multi-carousel'
import ProductBigCard from '../../../--components/--product-big-card'
import SectionTitle from '../../../--partials/--section-title'
import React from 'react'

function BestSellerProductsList() {
  return (
    <div className="flex justify-center w-full --best-seller-products-list">
        <div className="wrapper">
          <SectionTitle
          title={<> <span>Meilleures</span> ventes </>}
          />
            <Carousel
              responsive={{
                superLargeDesktop: {
                  breakpoint: { max: 4000, min: 3001 },
                  items: 1,
                },
                desktop: {
                  breakpoint: { max: 3000, min: 1025 },
                  items: 1,
                },
                tablet: {
                  breakpoint: { max: 1024, min: 465 },
                  items: 1,
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                },
              }}
              showDots={false}
              swipeable={true}
              draggable={true}
              autoPlaySpeed={5000}
              keyBoardControl={true}
              customTransition="transform 2000ms ease-in-out"
              transitionDuration={5000}
              partialVisible={false}
            >
              <div className="w-full grid grid-cols-1 width650:grid-cols-2 width1100:grid-cols-3 gap-x-[25px] gap-y-[20px] ">
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
              </div>
              <div className="w-full grid grid-cols-1 width650:grid-cols-2 width1100:grid-cols-3 gap-x-[25px] gap-y-[20px] ">
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
                  <ProductBigCard/>
              </div>
            </Carousel>
        </div>
      </div>
  )
}

export default BestSellerProductsList