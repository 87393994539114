import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Informations from './----informations'
import Orders from './----orders'
import PersonnalsInformations from './----informations/----persornals-informations'
import PassInformations from './----informations/----pass-informations'
import OrderDetails from './----orders/----order-details'
function Profile() {
  return (
    <div className='--cart-page w-full flex justify-center' >
        <div className="wrapper">
      <div id="contents" role="main" className="col-lg-12 col-md-12 col-sm-12">
        <div className="post-300 page type-page status-publish hentry">
          <header>
            <h2 className="entry-title">My Account</h2>
          </header>
          <div className="entry-content">
            <div className="woocommerce">
              <nav className="woocommerce-MyAccount-navigation">
                <ul>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--dashboard">
                    <a href="./profile">Dashboard</a>
                  </li>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--orders">
                    <a href="./profile/orders">Orders</a>
                  </li>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--edit-account">
                    <a href="./profile">Account details</a>
                  </li>
                  <li className="woocommerce-MyAccount-navigation-link woocommerce-MyAccount-navigation-link--customer-logout">
                    <a href="./">Logout</a>
                  </li>
                </ul>
              </nav>
              <div className="woocommerce-MyAccount-content">
                <div className="woocommerce-notices-wrapper" />

                <Routes>
                  <Route path='' element={<Informations />} />
                  <Route path='orders' element={<Orders />} />
                  <Route path='orders/:id' element={<OrderDetails />} />
                  <Route path='edit-account' element={<PersonnalsInformations />} />
                  <Route path='edit-password' element={<PassInformations />} />
                  <Route path={'*'} element={<Navigate replace to={'/'} />} />
                </Routes>
                
              </div>
            </div>
          </div>
          <div className="comment-single">
          </div>	
    
        </div>
      </div>

    </div>
    </div>
  )
}

export default Profile