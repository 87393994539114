import React from 'react'
import BigBanner from './----partials/----big-banner'
import BestSellerProductsList from './----partials/----best-seller-products-list'
import CompanyFeatures from '../../--partials/--company-features'
import BigStockProductsList from './----partials/----big-stock-products-list'
import NewArrivalsProductsList from './----partials/----new-arrivals-products-list'
import PubSmallBanner from '../../--partials/--pub-small-banner'
import PubFullBanner from '../../--partials/--pub-full-banner'
import HomeBlogList from './----partials/--home-blog-list'
import FullProductsList from './----partials/----full-products-list'

function Home() {
  return (
    <>
    <BigBanner/>
    <PubFullBanner/>
    <BestSellerProductsList/>
    <CompanyFeatures/>
    <NewArrivalsProductsList/>
    <PubSmallBanner/>
    <BigStockProductsList/>
    {/* <HomeBlogList/> */}
    <FullProductsList/>
    </>
  )
}

export default Home