import SideCategories from '../../--partials/--side-categories'
import SideBestSeller from '../../--partials/--best-seller'
import React from 'react'
import ProductImages from './----partials/----product-images'
import ProductTopDetails from './----partials/----product-top-details'
import RelatedProductsList from '../../--partials/--related-products-list'
import CustomReviewSar from '../../--components/--custom-review-star'

function ProductDetails() {
  return (
    <div className='--product-details w-full flex justify-center' >
        <div className="wrapper flex flex-col-reverse width650:flex-row gap-x-[25px] ">
          <div className="--product-details--left-section block width500:flex justify-center gap-x-5 width650:block">
            <SideBestSeller/>
            <SideCategories/>
          </div>

          <div className="--product-details--right-section">
              <div className="--product-top-infos flex flex-col miniLaptop:flex-row gap-y-5 miniLaptop:gap-y-0 ">
                <ProductImages/>
                <ProductTopDetails/>
              </div>

              <div className="--product-bottom-infos">
                <div className="tabbable">
                  <ul className="nav nav-tabs">
                    <li className="description_tab ">
                      <a href="#tab-description" data-toggle="tab">Description</a>
                    </li>
                    <li className="reviews_tab active">
                      <a href="#tab-reviews" data-toggle="tab">Reviews (0)</a>
                    </li>
                  </ul>
                  <div className="clear" />
                  <div className=" tab-content">
                    <div className="tab-pane " id="tab-description">
                      <h2>Description</h2>
                      <p>Proident adipisicing laborum beef ribs tri-tip dolore meatball tempor rump flank prosciutto elit do. Duis tenderloin culpa excepteur. Fugiat irure est cupim dolor, ut nulla id andouille chicken spare ribs eiusmod brisket biltong. Eiusmod minim tail cupim labore ad filet mignon, andouille esse enim. Sausage salami dolor ex adipisicing consequat. Ground round nostrud ut fatback voluptate consequat in minim drumstick culpa dolore. Ea beef prosciutto in sirloin fatback enim velit consectetur in pork belly pancetta culpa shank.</p>
                      <p>Shank quis in duis, id officia nulla. Pancetta sunt filet mignon porchetta doner turkey occaecat. Meatball corned beef elit ut fugiat. Hamburger biltong tail beef in cupim proident turducken picanha. Sausage chicken incididunt ad occaecat porchetta pancetta corned beef ham hock laborum nisi ullamco pork loin kielbasa aliqua.</p>
                      <p>In jerky minim chicken duis ground round nostrud pork belly occaecat pastrami commodo adipisicing tongue doner short loin. Officia est do, filet mignon shank pork loin anim esse quis kevin corned beef enim. Magna sint sirloin ham hock cupidatat laboris. Boudin spare ribs kevin meatloaf id short loin swine flank brisket aute. Reprehenderit turkey qui, boudin swine voluptate ipsum fugiat.</p>
                      <p>Salami in ball tip pig eiusmod occaecat pork chop, consequat excepteur incididunt. Ground round picanha ut boudin exercitation jerky meatball strip steak ipsum labore spare ribs turducken ribeye ut aliquip. Id ipsum esse nisi ball tip chuck adipisicing sint culpa t-bone brisket bresaola mollit. Enim eu kevin, tail in nisi nulla sirloin adipisicing veniam dolore.</p>
                      <p>Salami in ball tip pig eiusmod occaecat pork chop, consequat excepteur incididunt. Ground round picanha ut boudin exercitation jerky meatball strip steak ipsum labore spare ribs turducken ribeye ut aliquip. Id ipsum esse nisi ball tip chuck adipisicing sint culpa t-bone brisket bresaola mollit. Enim eu kevin, tail in nisi nulla sirloin adipisicing veniam dolore.</p>
                    </div>
                    <div className="tab-pane active" id="tab-reviews">
                      <div id="reviews" className="woocommerce-Reviews">

                        <div id="comments">
                          <h2 className="woocommerce-Reviews-title">
                            Reviews		
                          </h2>
                          <p className="woocommerce-noreviews">Il n'y a pas encore de commentaires.</p>
                          <ol className="commentlist">
                            <li className="review byuser comment-author-nguessanmarx even thread-even depth-1" id="li-comment-28">
                              <div id="comment-28" className="comment_container">
                                <img alt="" src="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=60&d=mm&r=g" srcSet="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=120&d=mm&r=g 2x" className="avatar pull-left media-object avatar-60 photo" height={60} width={60} loading="lazy" decoding="async" />
                                <div className="comment-text">
                                  <div className="star-rating" role="img" aria-label="Rated 4 out of 5"><span style={{width: '80%'}}>Rated <strong className="rating">4</strong> out of 5</span></div><span className="hidden" itemProp="itemReviewed" itemScope itemType="http://schema.org/Thing">
                                    <span itemProp="name">Donec vel mauris</span>
                                  </span>
                                  <p className="meta">
                                    <em className="woocommerce-review__awaiting-approval">
                                      Your review is awaiting approval		</em>
                                  </p>
                                  <div className="description"><p>hello<br />
                                      hhf</p>
                                  </div>
                                </div>
                              </div>
                            </li>{/* #comment-## */}
                            <li className="review byuser comment-author-nguessanmarx odd alt thread-odd thread-alt depth-1" id="li-comment-29">
                              <div id="comment-29" className="comment_container">
                                <img alt="" src="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=60&d=mm&r=g" srcSet="https://secure.gravatar.com/avatar/72a1e0ff27982ec433a81f549c438ade?s=120&d=mm&r=g 2x" className="avatar pull-left media-object avatar-60 photo" height={60} width={60} loading="lazy" decoding="async" />
                                <div className="comment-text">
                                  <div className="star-rating" role="img" aria-label="Rated 4 out of 5"><span style={{width: '80%'}}>Rated <strong className="rating">4</strong> out of 5</span></div><span className="hidden" itemProp="itemReviewed" itemScope itemType="http://schema.org/Thing">
                                    <span itemProp="name">Donec vel mauris</span>
                                  </span>
                                  <p className="meta">
                                    <em className="woocommerce-review__awaiting-approval">
                                      Your review is awaiting approval		</em>
                                  </p>
                                  <div className="description"><p>pooe</p>
                                  </div>
                                </div>
                              </div>
                            </li>{/* #comment-## */}
                          </ol>
                        </div>

                        <div id="review_form_wrapper">
                          <div id="review_form">
                            <div id="respond" className="comment-respond">
                              <span id="reply-title" className="comment-reply-title">Soyez le premier à commenter.</span>
                              <p className="must-log-in">Vous devez <a href="./login" className='font-bold text-[#001c96]'>être connecté</a> pour poster un commentaire.</p>	
                              <form action="" method="post" id="commentform" className="comment-form">
                                <div className="comment-form-rating flex items-center"><label htmlFor="rating">Your rating&nbsp;<span className="required">*</span></label>
                                  <p className="stars selected">
                                  <CustomReviewSar/>
                                  </p>
                                  
                                </div>
                                <p className="comment-form-comment">
                                  <label htmlFor="comment">Your review&nbsp;<span className="required">*</span></label>
                                  <textarea id="comment" name="comment" className='resize-none' cols={45} rows={8} required defaultValue={""} />
                                </p>
                                <p className="form-submit">
                                  <input name="submit" type="submit" id="submit" className="submit" defaultValue="Submit" /> 
                                </p>
                              </form>

                            </div>{/* #respond */}
                          </div>
                        </div>
                        <div className="clear" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <RelatedProductsList/>
          </div>
        </div>
        
    </div>
  )
}

export default ProductDetails