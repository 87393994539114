import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import CategoryLevelOne from './pages/category-leve-one'
import CategoryLevelTwo from './pages/category-leve-two'
import CategoryLevelThree from './pages/category-leve-three'

function CategoriesPage() {
  return  <Routes>
  <Route path=':slug' element={<CategoryLevelOne />} />
  <Route path='level-two/:slug' element={<CategoryLevelTwo />} />
  <Route path='level-three/:slug' element={<CategoryLevelThree />} />
  <Route path={'*'} element={<Navigate replace to={'/'} />} />
</Routes>
}

export default CategoriesPage