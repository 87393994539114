import React from 'react'

function PubFullBanner() {
  return (
    <div className="flex justify-center w-full">
    <div className="wrapper">
        <div className='--pup-three-banners flex'>
          <figure className="--pup-three-banner-big">
            <a href="#" target="_self" className="vc_single_image-wrapper   vc_box_border_grey"><img className="vc_single_image-img " src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/06/banner-id4-786x270.png" width={786} height={270} alt="banner-id4" title="banner-id4" /></a>
          </figure>

          <figure className="--pup-three-banner-small">
            <a href="#" target="_self" className="vc_single_image-wrapper   vc_box_border_grey"><img className="vc_single_image-img " src="https://demo1.wpthemego.com/themes/sw_furnicom/wp-content/uploads/2016/06/banner2-id4-385x270.jpg" width={385} height={270} alt="banner2-id4" title="banner2-id4" /></a>
          </figure>
        </div>
    </div>
  </div>
  )
}

export default PubFullBanner